@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&display=swap");
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 16px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: "Helvetica", "Arial", sans-serif;
  color: #000;
  background-color: #fff;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

picture img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
  margin: 5px;
}

.fd {
  color: #f6f6f6;
}

@font-face {
  font-family: "icomoon";
  src: url("fonts/iconFont/icomoon.eot?whsn3m");
  src: url("fonts/iconFont/icomoon.eot?whsn3m#iefix") format("embedded-opentype"), url("fonts/iconFont/icomoon.ttf?whsn3m") format("truetype"), url("fonts/iconFont/icomoon.woff?whsn3m") format("woff"), url("fonts/iconFont/icomoon.svg?whsn3m#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-]::before,
[class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-attention:before {
  content: "\e928";
}

.icon-copy:before {
  content: "\e91b";
}

.icon-allTime:before {
  content: "\e900";
}

.icon-appleAuth:before {
  content: "\e901";
}

.icon-arrorMicro:before {
  content: "\e902";
}

.icon-arrowCircle:before {
  content: "\e903";
}

.icon-barricade:before {
  content: "\e904";
}

.icon-browsers:before {
  content: "\e905";
}

.icon-burger:before {
  content: "\e906";
}

.icon-chartDonut:before {
  content: "\e907";
}

.icon-checkMark:before {
  content: "\e908";
}

.icon-contactFacebook:before {
  content: "\e909";
}

.icon-contactInstagram:before {
  content: "\e90a";
}

.icon-contactTelegram:before {
  content: "\e90b";
}

.icon-contactTwitter:before {
  content: "\e90c";
}

.icon-currencyEth:before {
  content: "\e90d";
}

.icon-defend:before {
  content: "\e90e";
}

.icon-discord:before {
  content: "\e90f";
}

.icon-envelopeOpen:before {
  content: "\e910";
}

.icon-facebookAuth:before {
  content: "\e911";
}

.icon-fileText:before {
  content: "\e912";
}

.icon-gearFine:before {
  content: "\e913";
}

.icon-globe:before {
  content: "\e914";
}

.icon-googleAuth:before {
  content: "\e915";
}

.icon-handPalm:before {
  content: "\e916";
}

.icon-incognito:before {
  content: "\e917";
}

.icon-lock:before {
  content: "\e918";
}

.icon-magic:before {
  content: "\e919";
}

.icon-mailReg:before {
  content: "\e91a";
}

.icon-passReg:before {
  content: "\e91c";
}

.icon-pencilCircle:before {
  content: "\e91d";
}

.icon-pencilSimple:before {
  content: "\e91e";
}

.icon-snowUI_logo:before {
  content: "\e91f";
}

.icon-switch:before {
  content: "\e920";
}

.icon-teatcher:before {
  content: "\e921";
}

.icon-telegram:before {
  content: "\e922";
}

.icon-telegramOnliLogo:before {
  content: "\e923";
}

.icon-trash:before {
  content: "\e924";
}

.icon-triangle:before {
  content: "\e925";
}

.icon-uploadSimple:before {
  content: "\e926";
}

.icon-userReg:before {
  content: "\e927";
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica/Helvetica.eot");
  src: local("../fonts/Helvetica/Helvetica"), url("../fonts/Helvetica/Helvetica.eot?#iefix") format("embedded-opentype"), url("../fonts/Helvetica/Helvetica.woff2") format("woff2"), url("../fonts/Helvetica/Helvetica.woff") format("woff"), url("../fonts/Helvetica/Helvetica.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica/Helvetica-Bold.eot");
  src: local("../fonts/Helvetica/Helvetica Bold"), local("../fonts/Helvetica/Helvetica-Bold"), url("../fonts/Helvetica/Helvetica-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Helvetica/Helvetica-Bold.woff2") format("woff2"), url("../fonts/Helvetica/Helvetica-Bold.woff") format("woff"), url("../fonts/Helvetica/Helvetica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
.wrapper {
  min-height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100vh;
}
.wrapper::-webkit-scrollbar {
  width: 6px;
}
.wrapper::-webkit-scrollbar-track {
  margin: 10px 0;
}
.wrapper::-webkit-scrollbar-thumb {
  background-color: #0b1320;
  border: 1px solid #fff;
  border-radius: 10px;
}

[class*=__container] {
  max-width: 95vw;
  padding: 0 15px;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  /*MOBILE*/
  [class*=__container] {
    max-width: none;
  }
}
.container {
  padding: 0 64px;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
}
.scrollbar::-webkit-scrollbar-track {
  margin: 10px 0;
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #0b1320;
  border: 1px solid #fff;
  border-radius: 10px;
}

.scrollbar-unvisable::-webkit-scrollbar {
  width: 0px;
}

.textareaInput::-moz-placeholder {
  transform: translate(0, 0.3em);
}

.textareaInput::placeholder {
  transform: translate(0, 0.3em);
}

.switchOnliBlue input :checked {
  background-color: #fff;
  background-color: var(--cui-form-check-input-checked-bg-color, var(--cui-primary));
  border-color: var(--cui-form-check-input-checked-border-color, var(--cui-primary));
}
.switchOnliBlue input {
  background-color: #fff;
  background-position: left center;
  --cui-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.87%29'/%3e%3c/svg%3e") !important;
  background-color: var(--cui-form-check-input-checked-bg-color, var(--cui-primary));
  border-color: var(--cui-form-check-input-checked-border-color, var(--cui-primary));
}

.switchClosed input :checked {
  --cui-form-switch-bg: url("../../img/icon/toggleLock.png") !important;
}
.switchClosed input {
  --cui-form-switch-bg: url("../../img/icon/toggleLock.png") !important;
}

.transitionImportant {
  transition: 0.3s !important;
}

.noHover {
  pointer-events: none;
}

.opacityNone {
  opacity: 0;
}

#calendar {
  transition: 0.3s;
  top: 100%;
  opacity: 0;
  pointer-events: none;
}
#calendar.active {
  top: calc(100% + 1em);
  opacity: 1;
  pointer-events: all;
}

.inputRedShadow input {
  box-shadow: 0 0 0 0.25rem rgba(233, 25, 25, 0.25);
}

.chartjs-tooltip {
  position: absolute;
  transition: 0.3s;
  background-color: rgba(26, 26, 26, 0.7019607843);
  z-index: 10;
  padding: 1em;
  overflow: hidden;
  border-radius: 10px;
  color: #fff;
  min-width: 10rem;
  font-family: "Inter";
  pointer-events: none;
  opacity: 0;
}

.notActive.notActive.notActive {
  color: var(--cui-sidebar-nav-link-color);
  background: var(--cui-sidebar-nav-link-bg);
}
.notActive.notActive.notActive div {
  color: var(--cui-sidebar-nav-link-icon-color) !important;
}

.vc-date__btn {
  pointer-events: none;
}

#calendar {
  pointer-events: none;
}
#calendar .vc-wrapper {
  pointer-events: none;
}
#calendar .vc-date {
  pointer-events: none;
}

#calendar.active {
  pointer-events: all;
}
#calendar.active .vc-date__btn {
  pointer-events: all;
}
#calendar.active .vc-wrapper {
  pointer-events: all;
}
#calendar.active .vc-date {
  pointer-events: all;
}

.bs-tooltip-auto {
  pointer-events: none;
  z-index: 0;
}